import React, { useEffect, useState } from "react";
import {
  Flex,
  TextInput,
  Card,
  Callout,
  Button,
  Divider,
  List,
  ListItem,
} from "@tremor/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { BarChart } from "@tremor/react";
import Credentials from "../credentials";
import DeleteConfirm from "../../../components/DeleteConfirm";
import * as devicesAPI from "../../../services/device";
import Alert from "../../../components/alert";

import ReactGA from "react-ga4";
import { GA_EVENTS } from "../../../services/ga-events";
 
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);


export default function SettingsForm({ device, onUpdate }) {
  const [name, setName] = useState(device.name);
  const [disabled, setDisabled] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [usage, setUsage] = useState({});
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    ReactGA.event({
      category: "Device Settings",
      action: GA_EVENTS.DEVICE_SETTINGS_VIEW,
    });
    setName(device.name);
    // get usage data for device
    const fetchUsage = async () => {
      try {
        const data = await devicesAPI.getDeviceUsage(device._id);
        
        let total = 0;

        // we want to show the last 24 hours of data and backfill with 0s
        const now = new Date();
        now.setMinutes(0);
        const last24Hours = new Date(now - 24 * 60 * 60 * 1000);
        const last24HoursData = data.filter(
          (item) => new Date(item._id.timestamp) > last24Hours,
        );

        // Create an array of the last 24 hours
        const hours = [];
        for (let i = 0; i < 24; i++) {
          const hour = new Date(now - i * 60 * 60 * 1000);
          hours.unshift(hour);
        }

        // Format data for tremor bar chart and backfill with 0s
        const bData = hours.map((hour) => {
          const item = last24HoursData.find((dataItem) => {
            const dataHour = new Date(dataItem._id.timestamp);
            return (
              dataHour.getHours() === hour.getHours() &&
              dataHour.getDate() === hour.getDate()
            );
          });

          const messages = item ? item.totalUplinkCount : 0;
          total += messages;

          return {
            datetime: hour.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }),
            Messages: messages,
          };
        });
        setUsage(bData);
        setTotalCount(total);
      } catch (err) {
        console.error("Error fetching usage data:", err);
      }
    };
    fetchUsage();
  }, [device]);

  const onDeleteConfirm = async () => {
    try {
      setDisabled(true);
      await devicesAPI.removeDevice(device._id);
      navigate("/devices");
    } catch (err) {
      console.error("Error deleting device:", err);
    }
  };

  const validateForm = () => {
    let errors = {};

    if (!name) errors.name = "Name is required";
    setErrors(errors);
    setDisabled(Object.keys(errors).length > 0);
    setTimeout(() => setErrors({}), 5000);
    console.log(errors);
    return Object.keys(errors).length > 0;
  };

  const onSave = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) return;

      await devicesAPI.patchDevice(device._id, { name });
      device.name = name;
      onUpdate(device);
    } catch (err) {
      console.error("Error updating device:", err);
      setDisabled(false);
    }
  };

  const onSetName = (e) => {
    setName(e.target.value);
    setErrors({});
    setDisabled(false);
  };

  return (
    <div>
      <DeleteConfirm
        isOpen={deleteOpen}
        onConfirm={onDeleteConfirm}
        closeModal={() => setDeleteOpen(false)}
        message="Are you sure you want to delete this device?"
      />

      <Divider />
      <section aria-labelledby="general-device-info">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2 className="scroll-mt-10 font-semibold text-gray-900">
              General
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Device basic info.
            </p>
          </div>
          <div className="space-y-6 md:col-span-2">
            <div>
              <Card>
                {Object.keys(errors).length > 0 && (
                  <Alert
                    title="Error"
                    message="Please correct the errors below:"
                  >
                    {Object.values(errors).map((error, index) => (
                      <div key={index}>{error}</div>
                    ))}
                  </Alert>
                )}
                <form>
                  <div className="grid gap-4 mb-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Name
                      </label>
                      <TextInput
                        id="name"
                        value={name}
                        placeholder="Humidor"
                        onChange={onSetName}
                      />
                    </div>
                  </div>
                  <Flex justifyContent={"end"}>
                    <Button onClick={onSave} disabled={disabled}>
                      Save
                    </Button>
                  </Flex>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <Divider />
      <section aria-labelledby="billing-usage">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2 className="scroll-mt-10 font-semibold text-gray-900">Usage</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Device to Cloud events for the last 24 hours.
            </p>
          </div>
          <div className="space-y-6 md:col-span-2">
            <h5 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Device messages for this Month
            </h5>
            <List>
              <ListItem>
                <p className="text-sm text-gray-700">
                  Total messages in the last 24 hours
                </p>
                <p className="text-xl font-semibold">{totalCount}</p>
              </ListItem>
              <ListItem></ListItem>
            </List>
            <Card>
              <BarChart
                className="mt-6"
                data={usage}
                index="datetime"
                categories={["Messages"]}
                colors={["blue"]}
                yAxisWidth={48}
              />
            </Card>
          </div>
        </div>
      </section>
      <Divider />
      <section>
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2 className="scroll-mt-10 font-semibold text-gray-900">
              MQTT Credentials
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Device MQTT credentials.
            </p>
          </div>
          <div className="space-y-6 md:col-span-2">
            <Card>
              <Credentials clientId={device.serial} />
            </Card>
          </div>
        </div>
      </section>
      <Divider />
      <section>
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2 className="scroll-mt-10 font-semibold text-gray-900">
              Danger Zone
            </h2>
          </div>
          <div className="space-y-6 md:col-span-2">
            <Card>
              <Callout className="mt-4" color="rose">
                <button
                  onClick={() => setDeleteOpen(true)}
                  disabled={disabled}
                  type="button"
                  className="focus:outline-none inline-flex text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                >
                  <TrashIcon className="w-5 h-5 mr-2" />
                  Remove Device
                </button>
              </Callout>
            </Card>
          </div>
        </div>
      </section>
      <Divider />
    </div>
  );
}
