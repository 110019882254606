import React, { useEffect, useState } from "react";
import {
  ProgressBar,
  Callout,
  Divider,
  Button,
  List,
  ListItem,
  Dialog,
  DialogPanel,
} from "@tremor/react";
import * as userAPI from "../../services/user";
import { BarChart } from "@tremor/react";
import PricingPlans from "../../components/PricingPlans";
import CancelSubscriptionDialog from "./cancel";
import ReactGA from "react-ga4";
import { GA_EVENTS } from "../../services/ga-events";
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

function MeterItem({ label, value, max }) {
  const maxLabel = max;
  const valueLabel = value;
  // adjust value and max to be percentage
  value = (value / max) * 100;
  max = 100;

  return (
    <div>
      <div>
        <h4 className="text-sm font-semibold text-gray-900 pb-1">{label}</h4>
        <ProgressBar value={value} max={max} className="mb-1" />

        <p className="text-tremor-default text-tremor-content">
          {valueLabel} of {maxLabel}
        </p>
      </div>
      <Divider />
    </div>
  );
}

export default function Billing({ user }) {
  const [plan, setPlan] = useState({});
  const [usage, setUsage] = useState({});
  const [showPlanPopup, setShowPlanPopup] = useState(false);
  const [showCancelPlanPopup, setShowCancelPlanPopup] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [message, setMessage] = useState("");

  const getPlan = async () => {
    try {
      const resp = await userAPI.getSubscription();
      setPlan(resp);
    } catch (err) {
      setPlan({ tier: "free" });
      console.error(err);
    }
  };

  const getUsage = async () => {
    const resp = await userAPI.getUsage();
    setUsage(resp);
  };

  const getFriendlyRetention = (days) => {
    if (days === 1) {
      return "24 hours";
    }
    return `${days} days`;
  };

  const getFriendlyFeature = (value) => {
    return value ? "Included" : "Not Included";
  };

  const onChangePlan = () => {
    setShowPlanPopup(true);
  };

  const onCancelPlanConfirm = async () => {
    console.log("cancel plan confirm");
    setCancelling(true);
    try {
      await userAPI.cancelSubscription();
      await getPlan();
      setCancelling(false);
      setShowCancelPlanPopup(false);
      setMessage("Subscription cancelled successfully");
      setTimeout(() => {
        setMessage("");
      }, 2000);
    } catch (err) {
      console.error(err);
    }
  };

  const onManageBilling = async () => {
    const resp = await userAPI.getBillingPortal();
    console.log(resp);
    // redirect to billing portal
    window.location = resp.url;
  };

  useEffect(() => {
    ReactGA.event({
      category: "Billing and Usage",
      action: GA_EVENTS.BILLING_VIEW,
    });
    getPlan();
    getUsage();
  }, []);

  return (
    <div className="mt-5">
      {message !== "" && (
        <Callout title={message} color="green" className="mb-4" />
      )}
      {plan.tier !== "free" ? (
        <Callout
          title={`Thank you for being a Premium Zafron Member!`}
          color="teal"
        ></Callout>
      ) : (
        <Callout title="This account is on a Free plan">
          Upgrade to a premium plan to unlock advanced features.
        </Callout>
      )}
      <div className="mt-5"></div>
      <Divider />
      <section aria-labelledby="billing-usage">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2 className="scroll-mt-10 font-semibold text-gray-900">
              Billing
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Overview of current billing cycle based on fixed and usage.
            </p>
          </div>
          <div className="space-y-6 md:col-span-2">
            <div>
              <div className="flex justify-between">
                <div>
                  <h4 className="text-sm font-semibold text-gray-900">
                    {plan.tier === "tier" ? "Free Plan" : "Premium Plan"}
                  </h4>
                  <p className="text-tremor-default text-tremor-content">
                    Make plan changes.{" "}
                  </p>
                  <p className="mt-4 text-tremor-label text-tremor-content ">
                    Please note that all invoices will be issued by Ticadia,
                    Inc., the legal entity behind Zafron.
                  </p>
                </div>
                {plan.tier !== "free" ? (
                  <div>
                    <Button
                      variant="secondary"
                      color="red"
                      onClick={() => setShowCancelPlanPopup(true)}
                    >
                      Cancel Plan
                    </Button>

                    <Button
                      type="submit"
                      className="ml-2"
                      onClick={onManageBilling}
                    >
                      Manage Billing
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button variant="primary" onClick={onChangePlan}>
                      Upgrade
                    </Button>
                  </div>
                )}
                <Dialog
                  open={showPlanPopup}
                  onClose={(val) => setShowPlanPopup(val)}
                  static={true}
                >
                  <DialogPanel style={{ "max-width": "44em" }}>
                    <h3 className="text-lg font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                      Pricing Plans
                    </h3>
                    <p className="mt-2 leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                      <PricingPlans
                        userId={plan.owner}
                        userEmail={user.email}
                      />
                    </p>
                    <Button
                      variant="secondary"
                      className="mt-8 w-full"
                      onClick={() => setShowPlanPopup(false)}
                    >
                      Got it!
                    </Button>
                  </DialogPanel>
                </Dialog>
                <CancelSubscriptionDialog
                  open={showCancelPlanPopup}
                  setOpen={setShowCancelPlanPopup}
                  confirmCancel={onCancelPlanConfirm}
                  cancelling={cancelling}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Divider />
      <section aria-labelledby="billing-usage">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
          <div>
            <h2 className="scroll-mt-10 font-semibold text-gray-900">Usage</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Stats for current month.
            </p>
          </div>
          <div className="space-y-6 md:col-span-2">
            <h5 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Uplinks for this Month
            </h5>
            <BarChart
              className="mt-6"
              data={usage.data}
              index="_id"
              categories={["Uplinks"]}
              colors={["blue"]}
              yAxisWidth={48}
            />

            <Divider />
            <div className="flex justify-between">
              <div>
                <h4 className="text-sm font-semibold text-gray-900">
                  Uplinks Per Hour
                </h4>
                <p className="text-tremor-default text-tremor-content">
                  Messages are throttled per hour.
                </p>
              </div>
              <div>
                <p className="text-tremor-default font-semibold">
                  {plan.hourlyEventLimit}
                </p>
              </div>
            </div>
            <Divider />
            <MeterItem
              label="Uplinks Per Month"
              value={usage.uplinks}
              max={plan.totalEventLimit}
            />

            <MeterItem
              label="Devices"
              value={usage.devices}
              max={plan.deviceLimit}
            />

            <MeterItem
              label="Rules"
              value={usage.rules}
              max={plan.rulesLimit}
            />

            <MeterItem
              label="Notifications"
              value={usage.notifications}
              max={plan.emailLimit}
            />

            <div className="flex justify-between">
              <div>
                <h4 className="text-sm font-semibold text-gray-900">
                  Data Retention
                </h4>
                <p className="text-tremor-default text-tremor-content">
                  How much data is retained and visible.
                </p>
              </div>
              <div>
                <p className="text-tremor-default font-semibold">
                  {getFriendlyRetention(plan.dataRetention)}
                </p>
              </div>
            </div>
            <Divider />
            <div>
              <h4 className="text-sm font-semibold text-gray-900">
                Additional Premium Features
              </h4>
              <List>
                <ListItem>
                  <span className="text-tremor-default">API Access</span>
                  <span>{getFriendlyFeature(plan.apiAccess)}</span>
                </ListItem>
                <ListItem>
                  <span>Data Export</span>
                  <span>{getFriendlyFeature(plan.dataExport)}</span>
                </ListItem>
                <ListItem>
                  <span>Logs Filtering</span>
                  <span>{getFriendlyFeature(false)}</span>
                </ListItem>
                <ListItem>
                  <span>Device Integration Support</span>
                  <span>{getFriendlyFeature(false)}</span>
                </ListItem>
              </List>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
