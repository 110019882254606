import { Flex, Button } from "@tremor/react";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import * as userAPI from "../../services/user";
import { useEffect, useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import rehypePrism from "@mapbox/rehype-prism";
const fieldStyle =
  "appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight bg-gray-50";

const exampleCode = `
#define CAYENNE_PRINT Serial
// Zafron MQTT Host
#define CAYENNE_DOMAIN "mqtt.zafron.dev"

#include <CayenneMQTTESP8266.h>

// Zafron MQTT credentials info.
char username[] = "VAR_USERNAME";
char password[] = "VAR_PASSWORD";
char clientID[] = "VAR_CLIENTID";

void setup() {
  Serial.begin(9600);
  Cayenne.begin(username, password, clientID, ssid, wifiPassword);
}
`;

export default function Credentials({ clientId, showExampleCode }) {
  const [showTooltip, setShowTooltip] = useState("");
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    const getCredentials = async () => {
      try {
        const data = await userAPI.getUserInfo();
        if (!data.mqttCredentials) throw new Error("No MQTT credentials found");

        setCredentials(data.mqttCredentials);
      } catch (err) {
        console.error("Error fetching credentials:", err);
      }
    };
    getCredentials();
  }, []);

  const getExampleCode = () => {
    // replace the placeholders with the actual credentials
    exampleCode.replace("VAR_USERNAME", credentials.username);
    exampleCode.replace("VAR_PASSWORD", credentials.password);
    exampleCode.replace("VAR_CLIENTID", clientId);
    return exampleCode;
  };

  const copyToClipboard = (field, value) => {
    navigator.clipboard.writeText(value);
    setShowTooltip(field);
    setTimeout(() => setShowTooltip(""), 2000);
    // You can add some visual feedback here if you like
  };

  return (
    <div>
      <Flex alignItems="start">
        <div className={`credentials mr-5 ${showExampleCode ? "w-1/2" : ""}`}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Host
              </label>
              <Flex>
                <pre className={fieldStyle}>mqtt.zafron.dev</pre>
                <button
                  onClick={() => copyToClipboard("host", "mqtt.zafron.dev")}
                  className="relative border rounded py-2 px-2 hover:bg-gray-100"
                >
                  <ClipboardDocumentIcon className="h-5 w-5" />

                  {showTooltip === "host" && (
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 z-10">
                      Copied!
                    </div>
                  )}
                </button>
              </Flex>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Port
              </label>

              <Flex>
                <pre className={fieldStyle}>1883</pre>
                <button
                  onClick={() => copyToClipboard("port", "1883")}
                  className="relative border rounded py-2 px-2 hover:bg-gray-100"
                >
                  <ClipboardDocumentIcon className="h-5 w-5" />

                  {showTooltip === "port" && (
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 z-10">
                      Copied!
                    </div>
                  )}
                </button>
              </Flex>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Username
              </label>
              <Flex>
                <pre className={fieldStyle}>{credentials.username}</pre>
                <button
                  onClick={() =>
                    copyToClipboard("username", credentials.username)
                  }
                  className="relative border rounded py-2 px-2 hover:bg-gray-100"
                >
                  <ClipboardDocumentIcon className="h-5 w-5" />

                  {showTooltip === "username" && (
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 z-10">
                      Copied!
                    </div>
                  )}
                </button>
              </Flex>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Password
              </label>
              <Flex>
                <pre className={fieldStyle}>{credentials.password}</pre>
                <button
                  onClick={() =>
                    copyToClipboard("password", credentials.password)
                  }
                  className="relative border rounded py-2 px-2 hover:bg-gray-100"
                >
                  <ClipboardDocumentIcon className="h-5 w-5" />

                  {showTooltip === "password" && (
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 z-10">
                      Copied!
                    </div>
                  )}
                </button>
              </Flex>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-1">
                Client Id
              </label>
              <Flex>
                <pre className={fieldStyle}>{clientId}</pre>
                <button
                  onClick={() => copyToClipboard("clientid", clientId)}
                  className="relative border rounded py-2 px-2 hover:bg-gray-100"
                >
                  <ClipboardDocumentIcon className="h-5 w-5" />

                  {showTooltip === "clientid" && (
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 z-10">
                      Copied!
                    </div>
                  )}
                </button>
              </Flex>
            </div>
          </form>
        </div>
        {showExampleCode && (
          <div className="w-1/2">
            <p className="tremor-default-content text-gray-500 text-sm mb-1">
              Example Arduino Code
            </p>
            <CodeEditor
              value={getExampleCode()}
              language="arduino"
              readOnly={true}
              padding={15}
              rehypePlugins={[[rehypePrism, { ignoreMissing: true }]]}
              style={{
                overflow: "auto",
                borderRadius: 5,
                border: "1px solid #e1e1e1",
                backgroundColor: "#333",
                fontFamily:
                  "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />

            <Flex justifyContent="end">
              <Button
                className="mt-2"
                variant="secondary"
                onClick={() => copyToClipboard("", getExampleCode())}
              >
                Copy to Clipboard
              </Button>
            </Flex>
          </div>
        )}
      </Flex>
    </div>
  );
}
