import React, { useState, useEffect } from "react";
import {
  Card,
  Text,
  Button,
  Flex,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Title,
  Divider,
  Badge,
} from "@tremor/react";
import { JSONTree } from "react-json-tree";
import Spinner from "../../../components/spinner";
import { theme } from "../../../components/JSONThreeThree";
import ReactGA from "react-ga4";
import { GA_EVENTS } from "../../../services/ga-events";
 
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const Waiting = function () {
  return (
    <>
      <div className="mt-4 flex h-52 items-center justify-center rounded-tremor-small border border-dashed border-tremor-border p-4 dark:border-dark-tremor-border">
        <div className="text-center">
          <Spinner className="text-center" />
          <p className="mt-2 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Waiting for data
          </p>
          <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
            Get started by connecting a device and send data.
          </p>
        </div>
      </div>
    </>
  );
};
const Debugger = ({ data, onClearEvent }) => {
  const [events, setEvents] = useState(data);
  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const onClearClick = () => {
    setEvents([]);
    onClearEvent();
  };

  useEffect(() => {
    ReactGA.event({
      category: "Debug Console",
      action: GA_EVENTS.DEBUG_VIEW,
    });
    // we have to sort the data by time latest on top
    const sorted = [...data].sort(
      (a, b) => new Date(b.time) - new Date(a.time),
    );
    setEvents(sorted);
  }, [data]);

  return (
    <Card className="max-w-full overflow-x-auto">
      <Flex justifyContent="between" className="mb-4">
        <div>
          <Title>Debug Events</Title>
          <Text>View live sensor data.</Text>
        </div>
        <div>
          <Button className="mr-2" variant="secondary" onClick={onClearClick}>
            Clear
          </Button>
        </div>
      </Flex>

      <Divider />
      {events.length === 0 ? (
        <Waiting />
      ) : (
        <Table className="w-full table-fixed">
          <TableHead>
            <TableRow className="bg-gray-50 border-b border-gray-200">
              <TableCell className="px-4 py-2 text-left text-gray-500 uppercase tracking-wider">
                Time
              </TableCell>
              <TableCell className="px-4 py-2 text-left text-gray-500 uppercase tracking-wider">
                Type
              </TableCell>
              <TableCell className="px-4 py-2 text-left text-gray-500 uppercase tracking-wider">
                Data Preview
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{ fontFamily: "Courier, monospace", fontWeight: 100 }}
          >
            {events.map((item, index) => (
              <TableRow
                key={index}
                className="transition duration-150 ease-in-out"
              >
                <TableCell>
                  <Flex>
                    <Text className="text-gray-900">
                      {item.time && new Date(item.time).toLocaleTimeString()}
                    </Text>
                  </Flex>
                </TableCell>
                <TableCell>
                  <Text className="text-gray-800">
                    {item.topic.indexOf("connected") !== -1
                      ? "CONNECTION"
                      : "DATA"}
                  </Text>
                </TableCell>
                <TableCell className="px-4 py-2 text-left">
                  {isJSON(item.event) ? (
                    <div className="overflow-x-auto border border-gray-100 px-1 py-1">
                      {/* Collapsible/Expandable JSON Viewer */}
                      <JSONTree
                        theme={{
                          extend: theme,
                        }}
                        data={JSON.parse(item.event)}
                        invertTheme={false}
                        hideRoot={true}
                      />
                    </div>
                  ) : (
                    <pre className="max-h-32 overflow-y-auto bg-gray-100 p-2 rounded-md">
                      {item.event}
                    </pre>
                  )}
                  <div className="mt-2">
                    <Badge
                      className="bg-gray-50 text-gray-900 ring-gray-500/30"
                      variant="neutral"
                    >
                      {`Topic: ${item.topic}`}{" "}
                    </Badge>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Card>
  );
};

export default Debugger;
