const WidgetLimits = {
  metric: { minH: 1, minW: 2, h: 2, w: 4 },
  areachart: { minH: 2, minW: 4, h: 2, w: 4 },
  barchart: { minH: 2, minW: 4, h: 2, w: 4 },
  gauge: { minH: 2, minW: 2, h: 2, w: 2 },
  switch: { minH: 1, minW: 2, h: 1, w: 2 },
  slider: { minH: 2, minW: 4, h: 1, w: 2 },
};

// Helper function to generate the initial layout
export const getDefaultDimensions = (widgetType) => {
  return WidgetLimits[widgetType] || WidgetLimits.metric;
};
