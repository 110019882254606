import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useContext,
} from "react";
import { useParams, Link } from "react-router-dom";
import {
  Title,
  TabGroup,
  Flex,
  Text,
  Tab,
  TabList,
  TabPanel,
  Badge,
  TabPanels,
  Icon,
  Button,
  Callout,
} from "@tremor/react";
import Nav from "../../components/nav";
import * as devicesAPI from "../../services/device";
import { getSubscription } from "../../services/user";
import LogPanel from "./log/logpanel";
import {
  WifiIcon,
  CpuChipIcon,
  BellAlertIcon,
  Cog6ToothIcon,
  CircleStackIcon,
  PlusCircleIcon,
  CommandLineIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import Spinner from "../../components/spinner";
import { toFriendlyTime } from "../../services/utils";
import RulesPage from "./rules/index";
import CapabilityForm from "./capability/form";
import SettingsForm from "./settings/form";
import Hero from "./onboarding/hero";
import MqttContext from "../../services/ws/MqttContext";
import { MQTTPacket } from "../../services/ws/MqttPacket";
import CapabilityDialog from "./capability/dialog";
import DashboardGrid from "../../components/DashboardGrid";
import Debugger from "./debug/debugger";

export default function DeviceDetail() {
  const { deviceId } = useParams();
  const [device, setDevice] = useState({
    mqttCredentials: {},
    capabilities: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(false);
  const [capabilities, setCapabilities] = useState([]);
  const capabilitiesRef = useRef();
  const [uplink, setUplink] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const [capability, setCapability] = useState({});
  const [name, setName] = useState("");
  const mqttClient = useContext(MqttContext);
  const [messages, setMessages] = useState([]);
  const [tab, setTab] = useState(0);
  const dashboardGridRef = useRef();
  const [usageAlert, setUsageAlert] = useState(false);

  const setStatus = (ts, thresholdMinutes = 5) => {
    if (!ts) {
      setIsOnline(false);
      return;
    }

    setUplink(ts);

    // Convert the device's timestamp and the current time to Date objects
    const deviceTime = new Date(ts);
    const currentTime = new Date();

    // Find the difference in milliseconds
    const difference = currentTime - deviceTime;

    // Convert the threshold to milliseconds and compare
    const thresholdMilliseconds = thresholdMinutes * 60 * 1000;

    //TODO: doesnt work, test later
    if (difference <= thresholdMilliseconds) {
      setIsOnline(true);
    } else {
      setIsOnline(false);
    }
  };

  const onUpdateCapability = async (updatedCapability) => {
    if (updatedCapability.new) {
      return await onAddCapability(updatedCapability);
    }

    try {
      const currentCapabilities = [...capabilitiesRef.current];
      const updatedDeviceState = await devicesAPI.patchDevice(device._id, {
        capabilities: [updatedCapability],
      });
      const updatedCapabilityState =
        updatedDeviceState.capabilities.find(
          (c) => c.channel === updatedCapability.channel,
        ) || capability;
      const index = currentCapabilities.findIndex(
        (item) => item.channel === updatedCapability.channel,
      );

      currentCapabilities[index] = updatedCapabilityState;
      dashboardGridRef.current.replaceWidget(updatedCapabilityState);

      // update existing capabilities with updated capability
      setCapabilities(currentCapabilities);
    } catch (err) {
      console.error("Error updating capability:", err);
    }

    // close modal
    closeModal();
  };

  const onAddCapability = async (capability) => {
    try {
      if (capability.new) {
        delete capability["new"];
        delete capability["_id"];
        capability.channel = String(capability.channel);
      }

      const currentCapabilities = [...capabilitiesRef.current];
      const newDeviceState = await devicesAPI.patchDevice(device._id, {
        capabilities: [capability],
      });
      // get new capability id and assign to dashboard widget

      const updatedCapability =
        newDeviceState.capabilities.find(
          (c) => c.channel === capability.channel,
        ) || capability;
      // lets replace the capability from the list and update the state
      const index = currentCapabilities.findIndex(
        (item) => item.channel === capability.channel,
      );
      if (index === -1) {
        currentCapabilities.push(capability);
      }

      currentCapabilities[index] = updatedCapability;
      dashboardGridRef.current.replaceWidget(updatedCapability);

      // update existing capabilities with updated capability
      setCapabilities(currentCapabilities);
      setIsOpen(false);
    } catch (err) {
      console.error("Error adding capability:", err);
    }
  };

  const onDeleteCapability = async (toDeleteCapability) => {
    try {
      await devicesAPI.removeCapability(device._id, toDeleteCapability);
      // lets replace the capability from the list and update the state
      const index = capabilities.findIndex(
        (item) => item.channel === toDeleteCapability.channel,
      );
      capabilities.splice(index, 1);
      dashboardGridRef.current.removeWidget(toDeleteCapability);
      setCapabilities([...capabilities]);
      closeModal();
    } catch (err) {
      console.error("Error deleting capability:", err);
    }
  };

  const onEditCapClick = async (capability) => {
    setCapability(capability);
    setIsOpen(true);
  };

  const onAddActuator = async () => {
    setCapability({
      type: "digital_actuator",
      channel: 0,
      name: "LED",
      unit: null,
      new: true,
      _id: "new-0",
      widget: { type: "switch", x: 0, y: 0, w: 2, h: 1, i: "new-0" },
    });
    setIsOpen(true);
  };

  const onClearEvent = () => {
    setMessages([]);
  };

  const onUpdate = async (device) => {
    setName(device.name);
  };

  useEffect(() => {
    const getDevice = async () => {
      try {
        const data = await devicesAPI.fetchOne(deviceId);
        setDevice(data);
        setCapabilities(data.capabilities);
        setIsLoading(false);
        setStatus(data.lastOnline);

        // Check for usage alert
        const subscription = await getSubscription();

        if (subscription.overLimit) {
          setUsageAlert(true);
        }
      } catch (err) {
        console.error("Error fetching device:", err);
      }
    };
    getDevice();
  }, [deviceId]);

  useEffect(() => {
    if (mqttClient) {
      const handleNewMessage = (topic, message) => {
        const packet = new MQTTPacket({ topic, payload: message });

        if (device.serial === packet.getSerial()) {
          // make sure the message is from the current device
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              event: message.toString(),
              time: new Date().getTime(),
              topic: topic,
            },
          ]);

          setStatus(new Date().getTime());
          const currentCapabilities = capabilitiesRef.current;
          const newCapabilities = [];
          packet.getCaps().forEach((cap) => {
            let capability = cap;
            const index = currentCapabilities.findIndex(
              (item) => item.channel + "" === cap.channel + "",
            );
            if (index === -1) {
              capability.new = true;
              capability.channel = cap.channel + "";
              // Set widget for new capabilities and grid layout properties
              if (capability.type === "gps") {
                capability.widget = {
                  type: "map",
                  x: 0,
                  y: 0,
                  w: 5,
                  h: 3,
                  i: "new-" + capability.channel,
                };
              } else {
                capability.widget = {
                  type: "metric",
                  x: 0,
                  y: 0,
                  w: 2,
                  h: 1,
                  i: "new-" + capability.channel,
                };
              }
              capability._id = "new-" + capability.channel;
              // -- end of widget properties
              newCapabilities.push(capability);
              dashboardGridRef.current.addNewWidget(capability);
            } else {
              currentCapabilities[index].value = cap.value;
            }
          });
          // Combine capabilitiesRef.current with newCapabilities and set to state
          // console.log('mqtt-event: setting new capabilities', newCapabilities);
          setCapabilities([...currentCapabilities, ...newCapabilities]);
        }
      };

      mqttClient.on("message", handleNewMessage);

      return () => {
        mqttClient.off("message", handleNewMessage);
      };
    }
  }, [mqttClient, device]);

  useEffect(() => {
    capabilitiesRef.current = capabilities;
  }, [capabilities]);

  useEffect(() => {
    setName(device.name);
  }, [device]);

  const onTabChange = (index) => {
    setTab(index);
  };

  const onActuatorAction = async (capability) => {
    // value
    const topic = `v1/${mqttClient.options.username}/things/${device.serial}/cmd/${capability.channel}`;
    const seq = Math.floor(Math.random() * 1000000);
    const payload = `${seq},${capability.value}`;
    await mqttClient.publish(topic, payload);
  };

  return (
    <div>
      <Nav />
      <main className="p-4 md:p-6 mx-auto max-w-7xl">
        {usageAlert && (
          <Callout
            title="Usage Alert"
            icon={ExclamationTriangleIcon}
            className="mb-3"
          >
            Warning: Please review your usage and consider upgrading your
            account to avoid service interruptions. Visit your
            <a href="/account" className="font-semibold">
              {" "}
              Account
            </a>{" "}
            or Device settings for details.
          </Callout>
        )}

        <Flex justifyContent="between">
          <Flex justifyContent="start" className="space-x-4">
            <Icon
              icon={CpuChipIcon}
              variant="light"
              size="xl"
              color={"indigo"}
            />

            <div>
              <Title>{name}</Title>
              <Text>
                {device.serial}{" "}
                {device.profile && (
                  <Link
                    to={`/profiles/${device.profile._id}/settings`}
                    className="text-blue-600"
                  >
                    {`(${device.profile.name})`}
                  </Link>
                )}
              </Text>
              {/* <CoffeeCup /> */}
            </div>
          </Flex>
          <div className="text-right w-1/2">
            {isOnline ? (
              <Badge color="green" icon={WifiIcon}>
                Online
              </Badge>
            ) : (
              <Badge color="rose">Offline</Badge>
            )}
            <Text className="mt-2 text-xs">
              Last Seen: {toFriendlyTime(uplink)}
            </Text>
          </div>
        </Flex>
        <TabGroup className="mt-6" onIndexChange={onTabChange}>
          <TabList>
            <Tab style={{ overflow: "unset" }} icon={CpuChipIcon}>
              Overview
            </Tab>
            <Tab style={{ overflow: "unset" }} icon={CircleStackIcon}>
              Logs
            </Tab>
            <Tab style={{ overflow: "unset" }} icon={BellAlertIcon}>
              Rules
            </Tab>
            <Tab style={{ overflow: "unset" }} icon={Cog6ToothIcon}>
              Settings
            </Tab>
            <Tab style={{ overflow: "unset" }} icon={CommandLineIcon}>
              Debug
            </Tab>
            {tab === 0 && (
              <Flex justifyContent="end">
                <Button
                  variant="light"
                  icon={PlusCircleIcon}
                  size="xs"
                  onClick={onAddActuator}
                >
                  New Command
                </Button>
              </Flex>
            )}
          </TabList>
          <TabPanels>
            <TabPanel className="mt-0">
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {(!capabilities || capabilities.length === 0) && (
                    <Hero device={device} isOnline={isOnline} />
                  )}
                  <CapabilityDialog isOpen={isOpen} closeModal={closeModal}>
                    <CapabilityForm
                      onCancel={closeModal}
                      onAction={onUpdateCapability}
                      onRemove={onDeleteCapability}
                      capability={capability}
                      formMode="edit"
                    />
                  </CapabilityDialog>
                  {/* <OverviewDateFilter /> */}
                  <div className="mt-2">
                    <DashboardGrid
                      ref={dashboardGridRef}
                      capabilities={capabilities}
                      deviceId={deviceId}
                      onAddCapability={onAddCapability}
                      onEditCapability={onEditCapClick}
                      onActuatorAction={onActuatorAction}
                    />
                  </div>
                </>
              )}
            </TabPanel>
            <TabPanel>
              {tab === 1 && (
                <LogPanel
                  deviceId={device._id}
                  capabilities={device.capabilities}
                />
              )}
            </TabPanel>
            <TabPanel>{tab === 2 && <RulesPage device={device} />}</TabPanel>
            <TabPanel>
              {tab === 3 && (
                <SettingsForm device={device} onUpdate={onUpdate} />
              )}
            </TabPanel>
            <TabPanel>
              {tab === 4 && (
                <Debugger
                  device={device}
                  data={messages}
                  onClearEvent={onClearEvent}
                />
              )}
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </main>
    </div>
  );
}
