import { Dialog } from "@headlessui/react";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { getEndpoint } from "../pages/sources/utils";
import { Button, Card } from "@tremor/react";

function Drawer({ isOpen, onClose, rowData }) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-30" />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                <div className="flex flex-col h-full bg-white shadow-xl">
                  <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
                    <h2 className="text-xl font-semibold text-gray-900">
                      {rowData ? rowData.name : "User"}
                    </h2>
                  </div>
                  <div className="relative flex-1 px-6 py-6 overflow-y-auto">
                    <div className="space-y-6">
                      {/* Provider Section */}
                      <div>
                        <h4 className="text-sm font-medium text-gray-500 uppercase">
                          Provider
                        </h4>
                        <p className="mt-2 text-base text-gray-900">
                          {rowData ? rowData.provider : "No data"}
                        </p>
                      </div>

                      {/* Endpoint Section */}
                      <div>
                        <h4 className="text-sm font-medium text-gray-500 uppercase">
                          Endpoint
                        </h4>
                        <div className="mt-2 relative">
                          <textarea
                            className="w-full p-3 text-sm font-mono border border-gray-200 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            value={getEndpoint(rowData)}
                            readOnly
                            onClick={(e) => e.target.select()}
                            rows={2}
                          />

                          <button
                            className="absolute bottom-3 right-3 px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                getEndpoint(rowData),
                              );
                            }}
                          >
                            Copy
                          </button>
                        </div>
                      </div>

                      {/* Event Count Section */}
                      <Card className="p-4 bg-gray-50 border-none">
                        <p className="text-sm font-medium text-gray-500 uppercase">
                          Event Count
                        </p>
                        <p className="mt-2">
                          <span className="text-2xl font-bold text-gray-900">
                            {rowData.eventCount}
                          </span>
                        </p>
                      </Card>

                      {/* Logs Section */}
                      <div>
                        <h4 className="text-sm font-medium text-gray-500 uppercase">
                          Logs
                        </h4>
                        <div className="mt-2">
                          <pre className="w-full h-96 p-4 text-sm font-mono bg-gray-50 border border-gray-200 rounded-lg overflow-auto">
                            {JSON.stringify(rowData.logs, null, 2)}
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 py-4 border-t border-gray-200 bg-gray-50">
                    <Button onClick={onClose} className="w-full justify-center">
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Drawer;
