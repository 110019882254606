// http://localhost:3000/account/checkout?session_id=cs_test_a1SP3LBaGL7mTYxJXTw2jnSdEc83lSrsInHQl90QUxCEUqQeT5lVLBKYaP
import React, { useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import Nav from "../../components/nav";
import { Callout, Button, Card, Icon, List, ListItem } from "@tremor/react";
import * as userAPI from "../../services/user";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const Logo = () => {
  return (
    <a href="/" className="flex items-center mb-6 text-2xl font-semibold">
      <img
        className="w-12 h-12 mr-2"
        src="https://zafron.dev/img/zafron.png"
        alt="logo"
        width={"50px"}
      />
      Zafron
    </a>
  );
};

const ThankYou = () => {
  return (
    <div>
      <div className="text-center">
        <Icon
          icon={CheckCircleIcon}
          size="xl"
          className="text-tremor-brand green-900"
        />
      </div>
      <h4 className="text-center font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
        Thank You for Subscribing!
      </h4>
      <p className="text-center mt-2 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
        A payment to Ticadia/Zafron will appear on your statement.
      </p>
      <List className="mt-4">
        <ListItem>
          <span className="text-tremor-content">
            Zafron Premium from Ticadia
          </span>
          <span>$7.00/month</span>
        </ListItem>
        <ListItem></ListItem>
      </List>
    </div>
  );
};

export default function Checkout() {
  // const { session_id, cancelled } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sessionId, setSessionId] = useState(searchParams.get("session_id"));
  const [cancelled, setCancelled] = useState(searchParams.get("cancelled"));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setLoading(true);
        const resp = await userAPI.getCheckoutSession(sessionId);
        setSession(resp);
      } catch (error) {
        setError("Error fetching session");
      } finally {
        setLoading(false);
      }
    };
    if (sessionId) fetchSession();
  }, [searchParams, setSearchParams, sessionId, cancelled]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        {/* <Spinner /> */}
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64">
        <div>
          <Logo />
          <Callout title="Error" color="red">
            {error}
          </Callout>

          <Link
            to="/account"
            className="inline-flex items-center gap-1 text-tremor-brand hover:underline hover:underline-offset-4"
          >
            Go back
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Nav />
      <main className="p-4 md:p-10 mx-auto max-w-7xl">
        <Card className="sm:mx-auto sm:max-w-md">
          {cancelled === "true" ? (
            <Callout title="Error" color="red">
              Checkout cancelled
            </Callout>
          ) : (
            <ThankYou />
          )}

          <Button
            onClick={() => (window.location.href = "/devices")}
            className="mt-4 w-full whitespace-nowrap rounded-tremor-default bg-tremor-brand py-2 text-center text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis"
          >
            Continue to Dashboard
          </Button>
        </Card>
      </main>
    </div>
  );
}
