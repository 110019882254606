import React, { useState, useEffect } from "react";
import { Card, Title, Button, Flex, Text, Divider } from "@tremor/react";
import ProfilesTable from "./table";
import ProfileForm from "./form";
import { Dialog, DialogPanel } from "@tremor/react";
import Nav from "../../components/nav";

import * as profilesAPI from "../../services/profiles";
import {
  PlusCircleIcon,
  CubeTransparentIcon,
} from "@heroicons/react/24/outline";

export default function Profiles() {
  const [profiles, setProfiles] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const refresh = async () => {
    try {
      await getProfiles();
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async (id) => {
    console.log(id);
    try {
      await profilesAPI.remove(id);
      await getProfiles();
    } catch (error) {
      console.error(error);
    }
  };

  const getProfiles = async () => {
    try {
      const response = await profilesAPI.list(page, limit);
      setProfiles(response.data);
      setTotal(response.total);
    } catch (error) {
      console.error(error);
    }
  };

  // Read operation
  useEffect(() => {
    getProfiles();
  }, []);

  return (
    <div>
      <Nav />
      <main className="p-4 md:p-10 mx-auto max-w-7xl">
        <Flex>
          <div>
            <Title>Profiles</Title>
            <Text>Profiles are device definitions for LoRa devices.</Text>
          </div>
          <div>
            <Button variant="primary" size="xs" onClick={() => setIsOpen(true)}>
              New Profile
            </Button>
          </div>
        </Flex>
        <Divider />
        <Card className="mt-6">
          {profiles.length > 0 ? (
            <ProfilesTable profiles={profiles} onDelete={onDelete} />
          ) : (
            <NoData open={() => setIsOpen(true)} />
          )}
        </Card>
      </main>
      <Dialog open={isOpen} onClose={(val) => setIsOpen(val)} static={true}>
        <DialogPanel>
          <ProfileForm onCancel={refresh} />
        </DialogPanel>
      </Dialog>
    </div>
  );
}

function NoData({ open }) {
  return (
    <>
      <div className="mt-4 flex h-52 items-center justify-center rounded-tremor-small border border-dashed border-tremor-border p-4 dark:border-dark-tremor-border">
        <div className="text-center">
          <CubeTransparentIcon
            className="mx-auto h-7 w-7 text-tremor-content-subtle dark:text-dark-tremor-content-subtle"
            aria-hidden={true}
          />

          <p className="mt-2 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Empty
          </p>
          <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
            Create a new profile and define data format and decoder for your
            devices.
          </p>
          <p></p>
          <Button
            icon={PlusCircleIcon}
            onClick={open}
            type="button"
            className="mt-6 inline-flex items-center gap-1.5 whitespace-nowrap rounded-tremor-small bg-tremor-brand px-3 py-2 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis"
          >
            New Profile
          </Button>
        </div>
      </div>
    </>
  );
}
