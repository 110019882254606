import React, { useEffect, useState } from "react";
import { ProgressCircle, Text } from "@tremor/react";

export default function Gauge({ highlight, capability }) {
  const [value, setValue] = useState(capability.value);
  const [percentValue, setPercentValue] = useState(0);

  // clean up value before displaying
  const cleanValue = (value) => {
    if (value && (typeof value === "number")) {
      return value.toFixed(2);
    } else if (typeof value === "string" && !isNaN(value)) {
      return parseFloat(value).toFixed(2);
    }
  }

  // adjust value to be within 0 and 100 percent ratio
  useEffect(() => {
    // const newValue = capability.value > 100 ? 100 : capability.value;
    const newPercentValue =
      ((capability.value - capability.widget.min) /
        (capability.widget.max - capability.widget.min)) *
      100;
    setPercentValue(Math.round(newPercentValue));
    setValue(capability.value);
  }, [capability.value, capability.widget.min, capability.widget.max]);

  return (
    <div className="flex flex-col items-center justify-center mt-2 h-full">
      <ProgressCircle value={percentValue} radius={64} size="lg">
        <span className="text-md font-medium text-slate-700">
          {cleanValue(value)}
        </span>
        <span className="text-md font-medium text-slate-700 ml-1">
          {capability.unit}
        </span>
      </ProgressCircle>
      <Text className="mt-2">{capability.type}</Text>
    </div>
  );
}
