import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Title,
  Flex,
  Text,
  Button,
  MultiSelect,
  MultiSelectItem,
  Select,
  SelectItem,
  Badge,
} from "@tremor/react";
import {
  ArrowPathIcon,
  ArrowUpOnSquareIcon,
} from "@heroicons/react/24/outline";
import Spinner from "../../../components/spinner";
import LogTable from "./logtable";
import * as devicesAPI from "../../../services/device";
import Pagination from "./pagination";
import moment from "moment";
import DateRange from "../../../components/DateRange";
import ReactGA from "react-ga4";
import { GA_EVENTS } from "../../../services/ga-events";
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

export default function LogPanel({ deviceId, capabilities }) {
  const [events, setEvents] = useState({ readings: [] });
  const [pakcetCount, setPacketCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [timeFrame, setTimeFrame] = useState("24h");
  const [loading, setLoading] = useState(false);
  const isFirstRender = useRef(true);
  const [limit, setLimit] = useState(10);

  // get filter values
  const [channels, setChannels] = useState([]);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);

  // set filter values
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);

  const getStartDate = () => {
    let startDate;
    switch (timeFrame) {
      case "15m":
        startDate = moment().subtract(15, "minutes").toISOString();
        break;
      case "1h":
        startDate = moment().subtract(1, "hours").toISOString();
        break;
      case "30m":
        startDate = moment().subtract(30, "minutes").toISOString();
        break;
      case "24h":
        startDate = moment().subtract(24, "hours").toISOString();
        break;
      case "3d":
        startDate = moment().subtract(3, "days").toISOString();
        break;
      case "1w":
        startDate = moment().subtract(1, "weeks").toISOString();
        break;
      case "1mo":
        startDate = moment().subtract(1, "months").toISOString();
        break;
      default:
        startDate = moment().subtract(15, "minutes").toISOString();
    }

    return startDate;
  };

  const getReadings = async () => {
    try {
      setLoading(true);
      const filters = {
        channels: selectedChannels,
        types: selectedTypes,
        units: selectedUnits,
      };
      let data;
      if (timeFrame === "-1") {
        data = await devicesAPI.fetchReadings(
          deviceId,
          currentPage,
          limit,
          null,
          null,
          filters,
        );
      } else {
        const endDate = moment().toISOString();
        const startDate = getStartDate();
        data = await devicesAPI.fetchReadings(
          deviceId,
          currentPage,
          limit,
          startDate,
          endDate,
          filters,
        );
      }
      setEvents(data);
      setTotalPages(data.totalPages);
      setPacketCount(data.count);
      setLoading(false);
      return data;
    } catch (err) {
      console.error("Error fetching device:", err);
    }
  };

  useEffect(() => {
    ReactGA.event({
      category: "Logs Console",
      action: GA_EVENTS.LOGS_VIEW,
    });
    if (deviceId === undefined) {
      return;
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;
      getReadings();
    } else {
      getReadings();
    }
  }, [
    deviceId,
    currentPage,
    selectedChannels,
    selectedTypes,
    selectedUnits,
    timeFrame,
    limit,
  ]);

  useEffect(() => {
    // map capabilities to filter values
    const filteredCapabilities = capabilities.filter((cap) => {
      return cap.type !== "digital_actuator" && cap.type !== "analog_actuator";
    });

    const channels = filteredCapabilities.map((cap) => cap.channel);
    const types = filteredCapabilities
      .filter((cap) => cap.type)
      .map((cap) => cap.type);
    const units = filteredCapabilities
      .filter((cap) => cap.unit)
      .map((cap) => cap.unit);

    setChannels([...new Set(channels)]);
    setTypes([...new Set(types)]);
    setUnits([...new Set(units)]);
  }, [capabilities]);

  const onChange = (page) => {
    setCurrentPage(page);
    getReadings();
  };

  const onDateSelector = async (value) => {
    setTimeFrame(value);
    await getReadings();
  };

  const onRefresh = () => {
    getReadings();
  };

  const onChannelChange = (value) => {
    // set selected channels currently with react state
    setSelectedChannels([...value]);
  };

  const convertJSONToCSV = (jsonData) => {
    const readings = jsonData.readings;
    const keys = Object.keys(readings[0].metadata);
    const csvRows = readings.map((reading) => {
      const metadataValues = keys.map((key) => reading.metadata[key]);
      const v =
        reading.metadata.type === "gps" ? reading.array_value : reading.value;
      return [reading.timestamp, ...metadataValues].join(",") + "," + v;
    });
    return ["timestamp," + keys.join(",") + ",value", ...csvRows].join("\n");
  };

  const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onExport = async () => {
    const jsonData = await getReadings(); // Assuming getReadings returns the JSON data
    const csvData = convertJSONToCSV(jsonData);
    downloadCSV(csvData, `zafron-logs-export-${new Date().getSeconds()}.csv`);
  };

  return (
    <div>
      <Card className="mt-5">
        <Flex>
          <div>
            <Flex justifyContent="start" className="space-x-2">
              <Title>Logs</Title>
              <Badge size="xs">{pakcetCount} Entries</Badge>
            </Flex>
          </div>
          <div>
            <Flex justifyContent="end" className="space-x-2"></Flex>
          </div>
        </Flex>

        {/* <Histogram /> */}
        <hr className="mt-2" />
        <div className="filter-export">
          <Flex className="space-x-2 mt-2 flex-wrap md:flex-nowrap">
            <Text className="w-32">Filter:</Text>
            <MultiSelect placeholder="Channel" onValueChange={onChannelChange}>
              {channels.map((channel) => (
                <MultiSelectItem key={channel} value={channel}>
                  {channel}
                </MultiSelectItem>
              ))}
            </MultiSelect>
            <MultiSelect placeholder="Type" onValueChange={setSelectedTypes}>
              {types.map((type) => (
                <MultiSelectItem key={type} value={type}>
                  {type}
                </MultiSelectItem>
              ))}
            </MultiSelect>
            <MultiSelect placeholder="Unit" onValueChange={setSelectedUnits}>
              {units.map((unit) => (
                <MultiSelectItem key={unit} value={unit}>
                  {unit}
                </MultiSelectItem>
              ))}
            </MultiSelect>
            <DateRange onDateSelector={onDateSelector} />
            <Button
              onClick={onExport}
              icon={ArrowUpOnSquareIcon}
              tooltip="CSV Export"
            ></Button>
            <Button
              onClick={onRefresh}
              tooltip="Refresh"
              variant="secondary"
              icon={ArrowPathIcon}
            ></Button>
          </Flex>
          <style jsx="true">{`
            @media (max-width: 768px) {
              .flex-wrap {
                flex-direction: column;
                align-items: flex-start;
              }
              .space-x-2 > * + * {
                margin-top: 8px;
                margin-left: 0;
              }
            }
          `}</style>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="logtable">
            <LogTable logs={events} />
            {events.readings.length > 1 && (
              <div className="mt-4">
                {/* show limit dropdown */}
                <Flex justifyContent="between">
                  <div className="mr-4 flex items-center">
                    <Text className="mr-2">Show:</Text>
                    <Select
                      placeholder="Limit"
                      onValueChange={setLimit}
                      defaultValue={limit}
                    >
                      <SelectItem value={10}>10</SelectItem>
                      <SelectItem value={25}>25</SelectItem>
                      <SelectItem value={50}>50</SelectItem>
                      <SelectItem value={100}>100</SelectItem>
                    </Select>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onChange={onChange}
                  />
                </Flex>
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
}
