import React, { useState } from "react";
import {
  Button,
  Text,
  Flex,
  Badge,
  List,
  ListItem,
  Callout,
} from "@tremor/react";
import Credentials from "../credentials";
import Spinner from "../../../components/spinner";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import CodeEditor from "@uiw/react-textarea-code-editor";
import rehypePrism from "@mapbox/rehype-prism";

const links = [
  {
    label: "Cayenne-MQTT-Arduino (for Arduino boards)",
    url: "https://github.com/myDevicesIoT/Cayenne-MQTT-Arduino",
  },
  {
    label: "Cayenne-MQTT-ESP (for ESP8266/ESP32)",
    url: "https://github.com/myDevicesIoT/Cayenne-MQTT-ESP",
  },
  {
    label: "Cayenne-MQTT-C (for C-based applications)",
    url: "https://github.com/myDevicesIoT/Cayenne-MQTT-C",
  },
  {
    label: "Cayenne-MQTT-CPP (for C++ applications)",
    url: "https://github.com/myDevicesIoT/Cayenne-MQTT-CPP",
  },
];

const Onboarding = ({ device, clientId, isOnline }) => {
  const [step, setStep] = useState(1);

  const steps = [
    {
      title: "How to send data to Zafron?",
      description:
        "Follow these steps to set up your device and start sending data to Zafron via MQTT.",
      content: (
        <div>
          <h3>
            Option 1: Use a Pre-Built Library
          </h3>
<Text className="mb-4">
If you’re using a compatible device, you can simplify the setup process by using one of our pre-built libraries:
</Text>
          <List>
            {links.map((link, index) => (
              <ListItem key={`library-link-${index}`}>
                <a
                  href={link.url}
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-500"
                >
                  {link.label}
                </a>
              </ListItem>
            ))}
          </List>
          <Text className="mt-4">
            Once you have installed the libraries, proceed to the next step.
          </Text>
          <h3 className="mt-6">
            Option 2: Use the MQTT API
          </h3>
          <Text className="mb-4">
For maximum flexibility, you can follow the <a className="text-blue-500" href="https://zafron.dev/docs/mqtt" target="_blank" rel="noreferrer">Zafron MQTT API documentation</a> to integrate your device with any MQTT client.
</Text>
          <Text>
            Need more help? Check out our{" "}
            <a
              className="text-blue-500"
              href="https://zafron.dev/docs/arduino"
              target="_blank"
              rel="noreferrer"
            >
              step-by-step guide.
            </a>
          </Text>
        </div>
      ),
    },
    {
      title: "Configure Your Device",
      description:
        "Use the credentials provided below to initialize the MQTT connection in your code.",
      content: <Credentials clientId={clientId} showExampleCode={true} />,
    },
    {
      title: "Test Connection",
      description:
        "Verify that your device is successfully connecting to the MQTT server.",
      content: (
        <div className="px-1">
          {!isOnline ? (
            <div>
              <Text className="mb-4">{`Waiting for connection...`}</Text>
              <Spinner />
            </div>
          ) : (
            <Badge size="md" color="green" icon={CheckCircleIcon}>
              Device is online and connected.
            </Badge>
          )}
        </div>
      ),
    },
    {
      title: "Start Monitoring",
      description: "Your device is now connected. Let's send some data!",
      content: (
        <div>
          <Text className="mb-4">
            Now that your device is online, it's time to start sending data.
            Devices can send different types of data, known as "Capabilities".
            Each capability corresponds to a specific type of data, such as
            temperature, humidity, or light levels.
          </Text>
          <Text className="mb-4">
            For example, if your device measures temperature, you would send a
            "Temperature Capability" with the temperature data.
          </Text>

          <Text className="font-bold mb-2">
            How to Send a Capabilities using Cayenne Arduino's code:
          </Text>
          <CodeEditor
            value={`CAYENNE_OUT_DEFAULT()
{
    // Write data to Cayenne here. This example just sends the current uptime in milliseconds on virtual channel 0.
    Cayenne.virtualWrite(0, millis());
    // Some examples of other functions you can use to send data.
    Cayenne.celsiusWrite(1, 22.0);
    Cayenne.luxWrite(2, 700);
    Cayenne.virtualWrite(3, 50, TYPE_PROXIMITY, UNIT_CENTIMETER);
}`}
            language="arduino"
            readOnly={true}
            plugins={[rehypePrism]}
          />

          <Callout title="Note" className="mt-4">
            This onboarding guide will disappear once a capability is detected.
          </Callout>
        </div>
      ),
    },
  ];

  const nextStep = () => {
    if (step < steps.length) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-xl font-semibold mb-4">Getting Started</h1>
      <Text className="mb-6">
        Follow the steps to set up your device. This allows you to start
        monitoring your data.
      </Text>

      {steps.map((s, index) => (
        <div
          key={index}
          className={`p-4 rounded-tremor-default border border-tremor-border bg-tremor-background ${index + 1 === step ? "bg-blue-50" : "border-gray-200 bg-white"} rounded-lg mb-4`}
        >
          <Flex className="items-center justify-between">
            <div>
              <h3 className="font-medium text-tremor-content-strong">
                {index + 1}. {s.title}
              </h3>
              {index + 1 === step && (
                <Text className="text-gray-600 mt-2">{s.description}</Text>
              )}
            </div>
            {index + 1 < step && <Badge color="green">Completed</Badge>}
          </Flex>
          {index + 1 === step && <div className="mt-4">{s.content}</div>}
        </div>
      ))}

      <Flex justifyContent="end" alignItems="center" className="mt-6 space-x-4">
        {step > 1 && (
          <Button variant="light" onClick={prevStep}>
            Back
          </Button>
        )}
        {step < steps.length && <Button onClick={nextStep}>Continue</Button>}
      </Flex>
    </div>
  );
};

export default Onboarding;
