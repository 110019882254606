import React from "react";
import Onboarding from "./redux";
import LoRaOnboarding from "./lora";

export default function Hero({ device, isOnline }) {
  return (
    <div className="">
      {device.profile && device.profile._id !== "" ? (
        <LoRaOnboarding profile={device.profile} />
      ) : (
        <Onboarding
          device={device}
          isOnline={isOnline}
          clientId={device.serial}
        />
      )}
    </div>
  );
}
