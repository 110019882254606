import { Dialog, DialogPanel, Button, Flex } from "@tremor/react";

export default function CancelSubscriptionDialog({
  open,
  setOpen,
  confirmCancel,
  cancelling,
}) {
  const onCancel = () => {
    confirmCancel();
  };

  return (
    <Dialog open={open} onClose={setOpen} static={true}>
      <DialogPanel className="sm:max-w-md">
        <div>
          <h4 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
            Cancel Subscription
          </h4>
          <p className="mt-2 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
            We are sorry to see you go. Are you sure you want to cancel your
            subscription?
          </p>
          <Flex className="mt-6" content="justify">
            <div className="mb-4 ">
              <Button
                onClick={(e) => setOpen(false)}
                variant="secondary"
                color="gray"
              >
                Cancel
              </Button>
            </div>
            <div className="mb-4 ">
              <Button
                onClick={onCancel}
                variant="primary"
                color="red"
                loading={cancelling}
              >
                Confirm Cancellation
              </Button>
            </div>
          </Flex>
        </div>
      </DialogPanel>
    </Dialog>
  );
}
