import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  TextInput,
  List,
  Text,
  ListItem,
  NumberInput,
  Select,
  SelectItem,
} from "@tremor/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Alert from "../../../components/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faChartLine,
  faCircleNotch,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { getDefaultDimensions } from "../../../components/dashboard/Types";

const properties = ["type", "channel", "unit", "value"];

const BarChartIcon = ({ icon }) => (
  <FontAwesomeIcon className="mr-2" icon={faChartBar} />
);

const LineChartIcon = ({ icon }) => (
  <FontAwesomeIcon className="mr-2" icon={faChartLine} />
);

const GaugeIcon = ({ icon }) => (
  <FontAwesomeIcon className="mr-2" icon={faCircleNotch} />
);

const ValueIcon = ({ icon }) => (
  <FontAwesomeIcon className="mr-2" icon={faTags} />
);

export default function CapabilityForm({
  onCancel,
  onAction,
  onRemove,
  capability,
}) {
  const [name, setName] = useState("");
  const [channel, setChannel] = useState("");
  const [datatype, setDatatype] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [isActuator, setIsActuator] = useState(false);
  const [widgetType, setWidgetType] = useState("metric");
  const [isAddingActuator, setIsAddingActuator] = useState(false);
  const [gaugeOptions, setGaugeOptions] = useState({ min: 0, max: 100 });

  const validateForm = () => {
    let errors = {};
    if (!name) errors.name = "Name is required";
    if (isActuator) {
      if (!channel) errors.channel = "Channel is required";
      if (!datatype) errors.datatype = "Datatype is required";
    }
    setErrors(errors);
  };

  useEffect(() => {
    capability.name = capability.name || capability.type;
    setName(capability.name);
    setChannel(capability.channel);
    setDatatype(capability.type);
    if (capability.widget && capability.widget.type) {
      setWidgetType(capability.widget.type);
    } else {
      setWidgetType("metric");
    }

    if (
      capability.type === "digital_actuator" ||
      capability.type === "analog_actuator"
    ) {
      setIsActuator(true);
      if (capability.new) {
        setIsAddingActuator(true);
      }
    }

    if (capability.widget && capability.widget.type === "gauge") {
      setGaugeOptions({
        min: capability.widget.min || 0,
        max: capability.widget.max || 100,
      });
    }
  }, [capability]);

  const onSubmit = () => {
    validateForm();
    capability.name = name;
    capability.channel = channel;
    capability.type = datatype;

    const widgetDimensions = getDefaultDimensions(widgetType);

    capability.widget = {
      ...capability.widget,
      type: widgetType,
      w: widgetDimensions.w,
      h: widgetDimensions.h,
    };

    if (widgetType === "gauge") {
      capability.widget.min = gaugeOptions.min || 0;
      capability.widget.max = gaugeOptions.max || 100;
    }

    setDisabled(true);
    onAction(capability);
  };

  const onGaugeOptionChange = (key, value) => {
    setGaugeOptions({ ...gaugeOptions, [key]: value });
  };

  return (
    <div className="relative">
      <Flex className="border-b pb-2 mb-2">
        <h3 className="text-lg font-semibold text-gray-700">
          {isAddingActuator ? "Add Actuator" : "Edit Capability"}
        </h3>
        <button
          type="button"
          onClick={onCancel}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <XMarkIcon />
          <span className="sr-only">Close modal</span>
        </button>
      </Flex>
      <div className="form-box">
        {isAddingActuator && (
          <Text className="mb-2">
            Use this form to create an actuator and send commands to your
            device.
          </Text>
        )}
        {Object.keys(errors).length > 0 && (
          <Alert title="Error" message="Please correct the errors below:">
            {Object.values(errors).map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </Alert>
        )}
        <form>
          <div className="grid gap-4 mb-4">
            <div>
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Name
              </label>
              <TextInput
                id="name"
                value={name}
                placeholder="Temperature Alert"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          {!isActuator && (
            <div>
              <div className="widgettype">
                <label
                  htmlFor="widget-type"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Widget Type
                </label>
                <Select value={widgetType} onValueChange={setWidgetType}>
                  <SelectItem value="areachart" icon={LineChartIcon}>
                    Area Chart
                  </SelectItem>
                  <SelectItem value="barchart" icon={BarChartIcon}>
                    Bar Chart
                  </SelectItem>
                  <SelectItem value="metric" icon={ValueIcon}>
                    Value
                  </SelectItem>
                  <SelectItem value="gauge" icon={GaugeIcon}>
                    Gauge
                  </SelectItem>
                </Select>
              </div>
              {widgetType === "gauge" && (
                <>
                  <div>
                    <label
                      htmlFor="min"
                      className="block mt-2 text-sm font-medium text-gray-900"
                    >
                      Gauge Min
                    </label>
                    <NumberInput
                      id="min"
                      value={gaugeOptions.min}
                      placeholder="Min"
                      onChange={(e) =>
                        onGaugeOptionChange("min", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="max"
                      className="block mt-2 text-sm font-medium text-gray-900"
                    >
                      Gauge Max
                    </label>
                    <NumberInput
                      id="max"
                      value={gaugeOptions.max}
                      placeholder="Max"
                      onChange={(e) =>
                        onGaugeOptionChange("max", e.target.value)
                      }
                    />
                  </div>
                </>
              )}

              <label className="block mt-2 text-sm font-medium text-gray-900">
                Properties
              </label>
              <List>
                {properties.map((item) => (
                  <ListItem key={item}>
                    <Text>{item}</Text>
                    <Text>{capability[item]}</Text>
                  </ListItem>
                ))}
              </List>
            </div>
          )}
          {isActuator && (
            <div>
              <div className="mt-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Channel
                </label>
                <NumberInput
                  id="channel"
                  value={channel}
                  placeholder="Channel"
                  onChange={(e) => setChannel(e.target.value)}
                />
              </div>
              <div className="mt-2">
                <label
                  htmlFor="datatype"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Type
                </label>
                <Select
                  defaultValue="digital_actuator"
                  value={datatype}
                  onValueChange={setDatatype}
                >
                  <SelectItem value="digital_actuator">
                    Digital Actuator
                  </SelectItem>
                  <SelectItem value="analog_actuator">
                    Analog Actuator
                  </SelectItem>
                </Select>
              </div>
            </div>
          )}
          <div className="mt-4"> </div>
        </form>
        <Flex className="border-t">
          <Button
            className="mt-2 bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Flex>
            {!capability.new ? (
              <button
                className="mt-2 ml-2 items-center justify-center text-red-500 text-sm hover:underline"
                onClick={() => onRemove(capability)}
              >
                {/* <PlusIcon /> */}
                <span>Remove Capability</span>
              </button>
            ) : (
              <div></div>
            )}
            <button
              disabled={disabled}
              className="mt-2 flex items-center justify-center text-white bg-blue-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              onClick={onSubmit}
            >
              {/* <PlusIcon /> */}
              <span>{capability.new ? "Create" : "Update"}</span>
            </button>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}
