import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Nav from "../../components/nav";
import {
  Flex,
  Text,
  Title,
  Icon,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import Decoder from "./decoder";
import * as profileAPI from "../../services/profiles";
import { CodeBracketIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import Config from "./config";

export default function ProfileSettings() {
  const { profileId } = useParams();
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const getDevice = async () => {
      try {
        const data = await profileAPI.fetchOne(profileId);
        setProfile(data);
      } catch (err) {
        console.error("Error fetching profile:", err);
      }
    };
    getDevice();
  }, [profileId]);

  return (
    <div>
      <Nav />
      <main className="p-4 md:p-10 mx-auto max-w-7xl">
        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <Flex justifyContent="between" className="items-center">
            <Flex justifyContent="start" className="space-x-4 items-center">
              <div className="bg-blue-50 p-3 rounded-lg">
                <Icon
                  icon={CodeBracketIcon}
                  variant="solid"
                  size="xl"
                  color="blue"
                />
              </div>
              <div className="space-y-1">
                <Title className="text-2xl font-bold text-gray-900">
                  {profile.name || "Loading..."}
                </Title>
                <Text className="text-gray-500">
                  {profile.serial || "Loading serial..."}
                </Text>
              </div>
            </Flex>
          </Flex>
        </div>

        <TabGroup className="mt-2">
          <TabList className="border-b border-gray-200">
            <Tab
              icon={CodeBracketIcon}
              className="px-6 py-3 text-sm font-medium focus:outline-none"
            >
              Decoder
            </Tab>
            <Tab
              icon={Cog6ToothIcon}
              className="px-6 py-3 text-sm font-medium focus:outline-none"
            >
              Settings
            </Tab>
          </TabList>
          <TabPanels className="mt-4">
            <TabPanel>
              <div className="bg-white rounded-lg p-6">
                <Decoder profileId={profileId} profile={profile} />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bg-white rounded-lg p-6">
                <Config profile={profile} />
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </main>
    </div>
  );
}
