import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Badge,
  Text,
} from "@tremor/react";

import { Link } from "react-router-dom";
import { toFriendlyTime } from "../../services/utils";

const headers = ["Serial", "Name", "Type", "Last Seen"];

export default function DevicesTable({ devices, onDelete }) {
  return (
    <Table>
      <TableHead>
        <TableRow className="border-b border-tremor-border text-black">
          {headers.map((header, i) => (
            <TableHeaderCell className="text-black" key={i}>
              {header}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {devices.map((device) => (
          <TableRow key={device._id} className="hover:bg-gray-50">
            <TableCell>
              <Link
                to={`/devices/${device._id}`}
                className="inline-flex items-center gap-1 text-tremor-brand hover:underline hover:underline-offset-4"
              >
                {device.serial}
              </Link>
            </TableCell>
            <TableCell>
              <Link to={`/devices/${device._id}`}>{device.name}</Link>
            </TableCell>
            <TableCell>
              {device.type === "mqtt" ? (
                <Badge className="tremor-brand-muted" size="xs">
                  MQTT
                </Badge>
              ) : (
                <Badge className="tremor-brand-muted" size="xs">
                  LoRaWAN
                </Badge>
              )}
            </TableCell>
            <TableCell>
              {device.lastOnline ? (
                toFriendlyTime(device.lastOnline)
              ) : (
                <Text>Never Seen</Text>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
